.ant-picker {
    /*background-color: black;*/
    /*color: #fff;*/
    width: 100% !important;
}
/*.ant-picker-input input {*/
/*    color: #ffffff !important;*/
/*}*/
/*.ant-picker-panels {*/
/*    background-color: black;*/
/*    color: #fff*/
/*}*/