.tld-action-icons {
    font-size: 1rem;
}
.tld-action-icons-edit {
    font-size: 1rem;
    color: #ffb035;
}
.tld-action-icons-delete {
    font-size: 1rem;
    color: #ff5253;
}